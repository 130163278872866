.login {
  overflow: hidden;
  position: relative;

  // m\argin: 2rem;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;

  .login-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 650px;
    width: 650px;

    @media (max-width: $md) {
      display: none;
    }
  }

  .login-image {
    width: 100%;
    height: 100%;
    object-fit: cover;

  }

  .signature {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    font-size: 0.8rem;

    .site-name {
      font-weight: 700;
      color: $nav-background;
    }

  }

  .form-card {
    text-align: center;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;


    img {
      width: 400px;

      @media (max-width:$sm) {
        width: 300px;
      }
    }


    .ant-typography {
      margin-bottom: .5rem;
    }

    .ant-typography-secondary {
      font-size: 16px;
      color: $secondary-color;
    }

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;

      .form-container {
        margin-top: 2rem;
        width: 60%;
        text-align: left;

        @media (max-width:$sm) {
          width: 90% !important;
        }
      }

      .anticon {
        color: $lighter-color;
      }

      input {
        margin-left: .5rem;
      }

      .ant-input-affix-wrapper-lg {
        outline: none;
        border: none;
        border-bottom: 1px solid $light-color;
      }

      .ant-form-item-explain-error {
        margin-left: 1rem;
      }

      .submit {
        display: flex;
        flex-direction: column;
        align-items: center;

        .submit-btn {
          background: $btn-bg;
          padding: .6em 2em 2em 2em;
          margin-top: 1em;
          width: 50%;
          border-color: $btn-bg !important;

          &:hover {
            background: #ffffff;
            border: 1px solid $btn-bg;
            color: $btn-bg;
          }

          @media (max-width:$sm) {
            font-size: 16px !important;
          }
        }

      }

      .ant-form-item-label {
        padding-bottom: 0 !important;
        padding-left: 7px !important;

        label {
          font-size: 16px !important;
          font-weight: 600 !important;
        }
      }
    }
  }

  .login-password-field {
    margin-bottom: .3em !important;
  }

  .forget-password {
    color: $btn-bg;
    padding-left: 0 !important;

    &:hover {
      background: none !important;
      font-weight: bold;
      color: $btn-bg;
    }
  }

  .create-one {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
    font-weight: 600 !important;

    button {
      padding: 0 !important;
      margin-left: .8em !important;
      font-weight: 700 !important;
      color: $btn-bg;

      &:hover {
        background: none !important;
        color: $btn-bg;
      }
    }
  }
}

.ant-modal-content {
  // .ant-modal-confirm-body-wrapper .form-container {
  //   margin-top: 2em;
  //   display: flex;
  //   align-items: center;

  //   .ant-form-item {
  //     width: 100%;
  //   }

  // }

  .ant-modal-confirm-btns button.ant-btn.css-dev-only-do-not-override-1kau3su.ant-btn-primary {
    background: $primary-color;
    color: $primary-color;
    color: white;

    &:hover {
      border: 1px solid $primary-color;
      color: $primary-color;
      background: white;
    }
  }

}

.signup-form {

  .ant-input-affix-wrapper-lg {
    outline: 1px !important;
    border: 1px solid $light-color !important;
  }

  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    border-color: #ff4d4f !important;
  }
}