.notification-page-title {
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $sm) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }
}

.all-complete {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    gap: 1rem;
    margin-right: 2rem;

    @media screen and (max-width: $sm) {
        width: 100%;
        margin-right: 0;
        margin-top: 1rem;
        justify-content: space-between;
    }

    .btn {
        padding: 0.5rem 0.75rem;
        font-weight: 600;
        // font-size: 1.1rem;

        &:hover {
            background-color: transparent;
        }
    }

    .link {
        color: #0433c1 !important;
    }
}

.notification-tab {
    height: 70vh !important;
    overflow-y: scroll;
    width: 100%;
    padding: 1rem 2rem;
}

.notification {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}

.read {
    background-color: #efefef !important;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.0) !important;
    pointer-events: none;
    opacity: 0.75;
}

.notification {
    border-radius: 1rem;
    padding: 1.2rem 1.5rem;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
    }

    .heading {
        margin: 0;
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 0.5rem;

        @media screen and (max-width: $md) {
            margin-top: 2rem;
        }

        @media screen and (max-width: $sm) {
            margin-top: 2.5rem;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 0.5rem;

        @media screen and (max-width: $sm) {
            gap: 0.2rem;


        }

        .message {
            font-size: 1rem;
            font-weight: 500;
            color: #303030;
            margin: 0;
            line-height: 1.5rem;
        }


        ul {
            list-style: none;
            padding: 0;
            display: flex;
            gap: 1rem;


            @media screen and (max-width: $sm) {
                margin: 0;
            }

            li {
                font-weight: 400;

                span {
                    margin-left: 0.5rem;
                    font-weight: 700;
                }
            }


        }

        .date-time {
            margin: 0;
            font-weight: 600;
            color: #303030;
        }
    }


    .status {
        position: absolute;
        top: 0.75rem;
        right: 0;
        padding: 0.5rem;
        font-size: 0.8rem;
        font-weight: 600;
    }

}


.settings {
    margin: 2.5rem 0;
    margin-right: 2rem;

    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        .ant-form-item {
            margin: 0;
        }

        p {
            margin: 0;
            font-size: 1.1rem;
            font-weight: 600;
        }
    }
}