.card-row {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;

    @media screen and (min-width: $sm) {
        flex-direction: row;
        gap: 1rem;
        margin-inline: 6rem;
    }
}

.analytics-card {
    margin: 0 auto;
    border: 1px solid #ccc;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
    background: #ffffff;
    box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.075);
    border-radius: 7px;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 10px;
    height: 100px;
    width: 100%;


    &:after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 10px;
        height: 100%;
        content: "";
        background: linear-gradient(81.67deg, #00aeef 0%, #005da4 100%);
    }


    @media screen and (min-width: $sm) {
        flex-direction: column;
        justify-content: center;
        width: 250px;
        height: 150px;

        &:after {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 10px;
            content: "";
            background: linear-gradient(81.67deg, #00aeef 0%, #005da4 100%);
        }
    }


    h1 {
        font-size: 2.75rem;
        font-weight: 700;
        margin: 0;
    }

    p {
        font-size: 1rem;
        font-weight: 400;
        margin: 0;
        color: #2c2c2c;
    }

}

.chart {
    padding: 1rem;

    canvas {
        height: 300px !important;
    }
}



.chart-title {
    font-size: 1rem !important;
    font-weight: 700 !important;
    margin: 0;
    color: #2c2c2c;
    // text-align: center;
    margin-bottom: 1rem !important;
    border-bottom: 1px solid #ccc;
    padding-bottom: 1rem;
}