.profile {
    display: flex;
    flex-direction: column !important;

    .ant-image {
        @media (max-width:$sm) {
            display: flex;
        }
    }

    .business-logo {

        // background-color: red;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 1rem;
        width: 150px;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: $sm) {
            margin-left: auto !important;
            margin-right: auto !important;
            width: 200px !important;
            height: 200px !important;
        }
    }

    .logo-container {
        // margin-right: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        // border-radius: 0.5rem;
        padding: 1rem;
        min-height: 150px;
        margin-bottom: 1.5rem;

        @media (max-width: $sm) {
            align-items: center;
        }

        .changer-icon {
            position: absolute;
            bottom: -1rem;
            right: 0.5rem;
        }
    }

    .profile-form {
        flex-wrap: wrap !important;

        @media (max-width:$sm) {
            .basic-form {
                height: auto !important;
            }
        }
    }

    @media (max-width:$sm) {
        height: 72vh !important;
        overflow-y: scroll !important;
        padding-top: 1em;
        padding-left: 0 !important;
    }

    @media (max-width:$sm) {
        .view-btn {
            margin-top: 1em !important;
        }
    }
}

.profile-form-height-col {
    max-height: 45vh !important;

    @media (max-width:$sm) {
        max-height: fit-content !important;
        margin-bottom: 10em !important;
    }

    &-password {
        max-height: 20vh !important;

        @media (max-width:$sm) {
            max-height: 20vh !important;
        }
    }
}

.change-btn {
    margin-top: 10px !important;
    border: none;
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #5a9ffb;
    font-size: 0.8rem;
}

.avatar-uploader {
    // background: red;
    min-height: 100px;
    min-width: 200px;
    display: flex;
    width: 100%;
    flex-grow: 1;
    align-items: stretch;
    justify-content: center;


    .ant-upload-list-picture-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 200px;
    }
}