.dashboard {
  height: 100vh;
  overflow: hidden;

  .ant-row {
    flex-wrap: nowrap;
  }

  .header {
    padding: 20px;
    background: white;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width:$sm) {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
      background-color: white !important;
      height: 8vh !important;
    }

    .header-logo {
      margin-top: 1.4em;
      width: 180px;
      height: 50px;
      object-fit: contain;
    }

    .header-logo-mobile{
        width: 150px;
        height: 40px;
        object-fit: contain;
    }

    ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light.css-dev-only-do-not-override-iwv6df {
      background: $nav-background;
      color: white;
    }

    span {
      color: white;
    }

    .usr-profile {
      cursor: pointer;
      color: $header-color;
      font-size: 16px !important;
      font-weight: 600 !important;

      .anticon {
        color: $header-color;
      }

      .user-icon {
        color: white !important;
      }
    }

  }




  .sidebar-btn {
    height: max-content;
    margin-top: .5em;
    max-width: 200px;
    background: white;
    color: $nav-background;
    font-size: 24px;
    text-align: center;
    margin-left: .5em;
    margin-bottom: 1em;

    &:hover {
      color: white;
      background-color: $nav-background;
    }
  }

  .icon-dark{
    display: flex;
    align-items: center !important;
  }

  .icon-dark span {
    font-size: 1.25rem;
    color: $nav-background !important;
  }

  .mobile-dashbar {
    background: white;
    color: $nav-background;
    // height: 8vh !important;

    .ant-menu-item {
      min-width: 19vw !important;
      max-width: 19vw !important;
      font-size: 18px;
      color: $nav-background;
      font-weight: 500;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      padding: 0 !important;
    }

    .ant-menu-item-icon {
      margin-left: 5px !important;
      font-size: 32px !important;
      text-align: center;
    }

    .ant-menu-item-selected {
      font-size: 24px !important;
      color: $nav-background;
      border-radius: 25px !important;

      .ant-menu-item-icon {
        margin-left: 5px !important;
        font-size: 50px !important;
        text-align: center;
      }
    }

    .ant-menu-item-selected::after {
      border-bottom-width: 0 !important;
    }

    .ant-menu-item:not(.ant-menu-item-selected):hover {
      background: white;
      color: $nav-background;
    }
  }

  .sidebar {
    background: white !important;
    color: white;
    height: 100vh;
    max-width: 200px;

    @media (max-width: $md) {
      width: 50px;
    }

    @media (max-width: $sm) {
      display: none;
    }

    .ant-menu-inline {
      min-width: 200px !important;
    }

    .ant-menu-light {
      background: white;
      color: $nav-background !important;
    }

    li {
      padding-left: 24px;

      &:hover {
        background: $nav-background;
        color: white;
        border: 2px solid $nav-background;
      }

      @media (max-width: $md) {
        padding-left: 9px;
      }
    }


    .ant-menu-item {
      font-size: 14px;
      color: $nav-background !important;
      font-weight: 500;
    }

    .ant-menu-item-icon {
      font-size: 22px;
      text-align: center;
      //padding-right: 14px;

      @media (max-width: $md) {}
    }


    .ant-menu-item-selected {
      background: $nav-background;
      color: white !important;
    }

    .ant-menu-item:not(.ant-menu-item-selected):hover {
      background: white;
      color: $nav-background;
    }
  }

  .layout-row {
    @media (max-width:$sm) {
      display: flex;
      flex-direction: column;
    }
  }
}

.ant-drawer-header-title {
  justify-content: flex-end;
  font-size: 1.25rem
}

.mobile-sidebar-logo {
  width: 200px;
  height: 50px;
  object-fit: contain;
}

.mobile-sidebar-menu {
  border-top: 1px solid #ccc;
  margin-top: 1em;
  padding-top: 1em;

  .ant-menu-item {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    color: $nav-background !important;
    font-weight: 500;
  }


}



.ant-dropdown-menu-item .ant-dropdown-menu-title-content {
  font-size: 16px !important;
  font-weight: 500 !important;

  .anticon {
    margin-right: 5px;
  }

  &:hover {
    color: $primary-color;
  }
}

.ant-layout {
  padding: 0;
}

.report-card {
  margin: 0em;
  background: white !important;
  border-radius: 10px;
  padding: 10px;
  height: 90dvh;
  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-width: $sm) {
    min-height: 80vh !important;
    overflow-x: hidden !important;
    margin-top: 3vh !important;
  }

  h2.ant-typography {
    margin: 0;
    margin-left: .5em;

    @media (max-width:$sm) {
      margin-left: 0 !important;
    }
  }

  .header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1em;
    padding-right: 1rem;
    gap: 12rem;

    @media (max-width:$md) {
      flex-direction: column;
      gap: 1rem;
    }

    .header-col {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 0 0.5rem;

    }

    .header-col:last-of-type {
      flex-grow: 1;
    }


    .breadcrumb {
      flex-grow: 1;
      display: flex;
      align-items: flex-start;
      gap: 0;
      flex-direction: column;
    }

    .btn-con {
      display: flex;
      justify-content: flex-end;
    }

    .btn-show-md {
      display: none;

      @media (max-width: $md) {
        display: flex;
      }
    }

    .btn-show-lg {
      display: flex;

      @media (max-width: $md) {
        display: none;
      }
    }
  }

  .ant-breadcrumb {
    cursor: pointer;
    margin-top: .5em;
    margin-left: 1em;

    @media (max-width:$sm) {
      margin-left: 0 !important;
    }
  }

  .bread-active {
    color: $nav-background;
  }

  .report-menu {
    margin-top: 1em;
    padding: 0;

    @media (max-width: $sm) {
      width: 80vw;
    }

    ul {
      li {
        .ant-menu-title-content {
          background-color: red;
        }
      }
    }

    .ant-menu-item {
      &:hover {
        color: $nav-background;
      }

      &:hover::after {
        border-bottom-color: #1d1e4d;
        border-width: 2px;
      }
    }

    .ant-menu-item-active .ant-menu-item-selected {

      background-color: red !important;

      &:hover {
        color: $nav-background !important;
      }
    }

    .ant-menu-item-active::after {
      border-bottom-color: #1d1e4d;
      border-width: 2px;
    }


    .ant-menu-item-selected {
      color: $nav-background;
    }

    .ant-menu-item-selected::after {
      border-bottom-color: #1d1e4d;
      border-width: 2px;
    }

    .ant-menu-item:not(.ant-menu-item-selected):hover {
      background: white;
      color: $nav-background;
    }

  }

  .export-btn {
    border-color: $btn-bg;
    background: $btn-bg;
    color: white;
    padding: .4em 1.5em 1.5em 1.5em;
    width: 150px;
    float: right;

    &:hover {
      background: #ffffff;
      border: 1px solid $btn-bg;
      color: $btn-bg;
    }

    @media (max-width: $sm) {
      margin-top: .5em !important;
    }
  }

  .footer {
    position: absolute;
    bottom: .2em;
    left: 50vw;
    margin-top: .5em;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .site-name {
      font-weight: 700;
      color: $nav-background;
    }

    @media (max-width: $md) {
      left: 42vw;
    }

    @media (max-width: $sm) {
      display: none;
    }
  }
}


.dashboard-layout {
  padding: 0 24px 24px;

  @media (max-width: $md) {
    padding: 0 6px 6px;
  }

  @media (max-width: $sm) {
    min-height: 82vh !important;
    overflow-y: scroll !important;
  }

  .site-layout-background {
    padding: 24px;
    margin: 0;
    min-height: 280px;

    @media (max-width: $md) {
      padding: 12px;
    }

    @media (max-width: $sm) {
      padding: 0;
    }
  }
}

.input-label-text {
  font-weight: 700;
}

.danger-btn {
  margin-left: 10px;

  @media (max-width: $md) {
    margin-left: 0;
    margin-top: .7em;
  }
}

.report-select {
  margin-top: 1em !important;
  margin-left: 1em !important;
  flex-wrap: wrap;

  @media (max-width: $sm) {
    display: flex;
    flex-direction: column;

    .ant-col-6 {
      margin-top: .3rem;
      max-width: 90% !important;
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 1375px) {
    display: flex;

    .ant-col-6 {
      margin-top: .3rem;
      max-width: 90% !important;
      display: flex;
      flex-direction: column;
    }
  }
}

div#\:r1\: {
  font-size: 28px;
}

div#\:r3\: {
  font-size: 28px;
}

.card-row {
  margin: 1rem;
  margin-inline: 6rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media screen and (max-width: $sm) {
    flex-direction: column;
    gap: 1rem;
  }
}

.chart-row {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media (max-width: $md) {
    width: 90vw;
  }

  @media (max-width: $sm) {
    margin-bottom: 1em;
    flex-direction: column;

    .ant-col-12 {
      margin-top: 1.5em;
      max-width: 100%;
    }
  }
}

.report-component {
  overflow-y: scroll;
}

none .chart {
  padding: 2em;

  @media (max-width: $sm) {
    padding: 10px;

  }
}

.report-component {
  @media (max-width: $sm) {
    margin-top: 3vh !important;
    height: 70vh;
    overflow-y: scroll;
    border-radius: 15 !important;
  }
}

.chart-component {
  height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-width: $md) {
    height: auto;
  }

  .ant-select {
    width: 65%;

    @media (max-width: $sm) {
      width: 100%;
    }
  }
}


.ant-btn-primary {
  background-color: $btn-bg;
}

.mb-3 {
  margin-bottom: 1rem;
}

.client-card-label {
  font-weight: 700 !important;
}

.w-fix {
  width: 200px !important;
}

.heading-add-client-form {
  font-size: 1.25rem;
  font-weight: 700;
  margin: 1rem 0;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    margin: 0.5rem;
  }
}

.location-input {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
  // background-color: pink;
  margin-bottom: 1.5rem;


  .form-icon {
    font-size: 1.1rem;
  }

  .location {
    background-color: #bf1414;
    color: #fff;
    border: none;

    &:hover {
      background-color: #e81313;
      color: #fff;
      border: none;
    }
  }

  .col {
    display: flex;
    flex-direction: column;

    .ant-form-item {
      margin-bottom: 0;
    }

    p {
      margin: 0;
    }

    label {
      margin-bottom: 0.5rem;
      font-weight: 700;
    }

    .content {
      display: flex;
      gap: 0.75rem;


      @media screen and (min-width: $sm) {
        margin-right: 1rem;
      }

      a,
      p {
        flex-grow: 1;
      }
    }
  }

  @media screen and (min-width: $sm) {
    grid-template-columns: 0.75fr 1fr 1.5fr;
    gap: 1.5rem;
  }

}


.client-profile-image-details {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media screen and (min-width: $md) {
    margin: 0 5rem;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

}

.client-img-wrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  .client-image {
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    overflow: hidden !important;
    border: 1px solid #ccc;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .change-picture {}

}


.float-right {
  display: flex;
  justify-content: flex-end;
}


.new-stacking-context {
  position: relative;
}

.map {
  width: 100%;
  height: 400px;
}


.combobox-list {
  position: relative;
  z-index: 30000 !important;
  background-color: #ffffff;
  box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.2);
}

.combobox-input-control {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;

  label {
    font-weight: 600;
  }

  .combobox-input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 1rem;

    &:focus {
      outline: none;
    }

    &:hover {
      border: 1px solid #ccc;
    }

    &:active {
      border: 1px solid #ccc;
    }

    &:disabled {
      background-color: #f5f5f5;
    }

    &::placeholder {
      color: #ccc;
    }
  }
}

.auto-complete {
  z-index: 2;
}

.warning {
  padding: 0.5rem 0;

  p {
    color: red;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
    margin: 0;
  }
}

.location-input:disabled {
  background-color: #fff;

  &::placeholder {
    color: #000;
  }
}


.gap-1 {
  display: flex;
  gap: 1rem;
}

.dyanmic-form-input-row {
  position: relative;

  .add-site-button {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 1rem;
  }
}