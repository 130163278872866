.basic-form {
    height: 70vh;
    font-size: 18px !important;
    margin-top: 2em;
    margin-left: 1em;
    padding-right: 25%;

    .ant-form-item-label {
        text-align: start !important;

        label {
            font-size: 16px !important;
            font-weight: 700 !important;
        }
    }

    .form-submit-row {
        display: flex;
        justify-content: flex-end;
        width: 100% !important;
    }

    .ant-form-item-control {
        margin-bottom: .5em !important;
    }

    .view-btn {
        font-size: 16px !important;
    }

    @media (max-width:$md) {
        padding-right: 0 !important;
    }

    @media (max-width:$sm) {
        max-width: 95vw !important;
        height: 63vh !important;
        margin-left: 0 !important;
        overflow-y: scroll !important;

        .ant-input {
            font-size: 16px !important;
        }
    }

    .unrequired-fields {
        label {
            margin-left: 10px !important;
        }
    }

    .add-btn {
        border-color: $btn-bg;
        color: $nav-background;

        &:hover {
            border-color: $btn-bg !important;
            border: 1px solid $btn-bg;
            color: $btn-bg !important;
            font-weight: 600 !important;
        }
    }

}

.form-submit-row {
    display: flex;
    justify-content: flex-end;
    width: 100% !important;
}

.double-col-form {
    padding-right: 0 !important;
    margin-top: 1em !important;

    .double-column-from-row {
        @media (max-width:$sm) {
            display: flex !important;
            flex-direction: column !important;
        }
    }

    .dyanmic-form-input-row {
        display: flex !important;
        flex-direction: column !important;
    }

    .form-submit-row {
        display: flex;
        justify-content: flex-end;
        width: 98% !important;
    }

    .ant-divider-with-text {
        margin: 0 !important;
        margin-bottom: 1em !important;
    }

    @media (max-width:$sm) {
        .ant-form-item {
            margin-bottom: 0 !important;
        }
    }

}

.modal-form {
    margin-top: 1em;
    margin-bottom: 2em !important;

    .ant-form-item-label {
        text-align: start !important;

        label {
            font-size: 16px !important;
            font-weight: 600 !important;
        }
    }
}

.devider {
    @media (max-width:$sm) {
        margin-top: 1em !important;
    }
}


.input-disabled {
    color: #000 !important;
    border: none;
    border: 1px solid #ccc;
    border-radius: 5px !important;


    &:hover {
        border: none;
        border-bottom: 1px solid #ccc;
        border-radius: 0 !important;
    }

    &:focus {
        border: none;
        background-color: #fff !important;
        border-bottom: 1px solid #ccc;
        border-radius: 0 !important;
    }

}