.client-site-select {
  margin-left: 1em;
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: $header-color;

  @media (max-width: $sm) {
    flex-direction: column;
    margin-top: 2.5rem !important;

    .ant-col {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.ant-select {
  outline: $nav-background;
}

.submit-report {
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .audit-card {
    .ant-card-body {
      padding: 0;

      .header {
        height: 2vh;
        border-radius: 5px 5px 0 0;
        background-color: $nav-background;
        color: white;
        margin: 0;
        display: flex;
        justify-content: flex-start;
      }
    }
  }

  @media (max-width: $sm) {
    margin-top: 2.5rem;
  }
}

.ant-select-selector:hover,
.ant-select-selector:focus-within {
  border: 1px solid $nav-background !important;
}

.ant-select-item-option-content:hover {
  color: $nav-background;
}

.site-audit-report {
  .ant-collapse {
    margin-bottom: .5em;
  }

  .ant-collapse-header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .ant-collapse-extra {
    order: 1;
    margin-right: .4em;
  }

  .ant-collapse-header-text {
    order: 2;
  }

  .ant-collapse-expand-icon {
    position: absolute;
    right: 1em;
  }
}

.submit-btn {
  border-color: $btn-bg;
  background-color: $btn-bg;
  color: white;

  &:hover {
    color: $btn-bg !important;
    background-color: white;
    border-color: $btn-bg !important;
  }

  @media (max-width: $md) {
    width: 10vw;
  }

  @media (max-width: $sm) {
    width: 35vw;
    float: left;
  }
}

.submit-btn-hover {
  border-color: $nav-background;
  background-color: white;
  color: $nav-background;

  &:hover {
    color: white !important;
    background-color: $nav-background !important;
    border-color: $nav-background !important;

    span {
      color: white !important;
    }
  }

  @media (max-width: $md) {
    width: 10vw;

    .anticon {
      display: none;
    }
  }
}

.acordian-container {
  height: 48vh;
  overflow-y: scroll;
  margin-bottom: .5em;

  @media (max-width: $md) {
    height: 60vh;

    .ant-collapse .ant-collapse-content>.ant-collapse-content-box {
      padding: 0;
    }
  }

  @media (max-width: $sm) {
    height: 38vh;
  }
}

.export-btn-row {
  display: flex;
  margin-right: 1em;
  justify-content: flex-end;
  position: relative;
  margin-top: 3rem;
}

.select-rate {
  width: 12vw;

  @media (max-width: $md) {
    width: 16vw;
  }

  @media (max-width: $sm) {
    width: 35vw;
  }
}