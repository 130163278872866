.job-details-header-row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: baseline;
}

.job-details-header {
  margin-left: 1rem;
  margin-top: .5em !important;

  @media (max-width: $sm) {
    font-size: 16px !important;
    margin-left: 0 !important;
    margin-bottom: 15px !important;
  }
}

.client-job-card {

  .ant-card-body {
    height: 25vh;
    overflow-y: scroll;

    @media (max-width: $sm) {
      height: fit-content;
    }
  }
}

.job-card {
  min-height: 27vh !important;
  background-color: #fafafa;
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;

  .ant-card-head {
    min-height: 40px !important;
    margin-bottom: 5px !important;
    font-weight: 700 !important;
    border-bottom: 2px solid #edebeb !important;
  }

  .ant-card-body {
    padding-top: 0px !important;
  }

  .job-card-label {
    font-weight: 500;
  }

  @media (max-width:$sm) {
    min-height: 25vh !important;
  }
}

.job-view-cards {
  height: 70vh !important;
  overflow-x: hidden !important;

  .other-table .ant-table-wrapper {
    min-height: fit-content !important;
  }
}

@media (max-width: $md) {
  .job-view-card-res {
    display: flex;
    flex-direction: column;

    .ant-col-md-24.ant-col-lg-8.css-dev-only-do-not-override-17p3bx5 {
      max-height: 32%;
    }
  }

  .job-view-cards {
    height: 80vh;
    overflow-y: scroll;
    padding-bottom: 100px !important;
  }
}

@media (max-width: $sm) {
  .bottom-card {
    margin-bottom: 1rem;
  }
}


.job-view-cards {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media (max-width: $md) {
    grid-template-columns: 1fr;
  }

  .full-row {
    padding: 0 1rem;

    @media (min-width: $md) {
      grid-column: span 2;
    }

  }

  // .job-view-card:nth-last-of-type(2n-1) {
  //   border-right: 1px solid #ccc;
  // }

  .job-view-card {
    // min-height: 300px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 1rem;

    @media (max-width: $sm) {
      font-size: 12px;
    }


    h3 {
      margin: 0;
      margin-bottom: 1rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #ccc;

      @media (max-width: $sm) {
        font-size: 12px !important;
      }
    }

    .row {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 1rem;
      padding: 0 1rem;

      // margin-bottom: 0.5rem;
      @media (max-width: $sm) {
        padding: 0;
        font-size: 12px !important;
      }

      h5 {
        font-size: 1rem;
        font-weight: 600;
        margin: 0;

        @media (max-width: $sm) {
          font-size: 12px !important;
        }
      }

      p {
        font-size: 1rem;
        font-weight: 400;
        margin: 0;

        @media (max-width: $sm) {
          font-size: 12px !important;
        }
      }

    }

    .img-row {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .heading {
        margin: 0;
        margin-bottom: 0.5rem;
      }

      .imgs {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
      }
    }

  }

}

.jobview-modal {
  // background-color: red !important;

  .ant-modal-content {
    padding: 10px !important;

    @media (max-width: $sm) {
      padding: 0;
    }
  }

  .report-card {
    height: fit-content !important;

    @media (max-width: $sm) {
      padding: 0;
    }
  }
}

.ant-row.job-view-modal-header {
  @media (max-width: $sm) {
    flex-direction: column;
  }
}

.cancel-reccuring-btn {
  margin-right: 16px !important;

  @media (max-width: $sm) {
    font-size: 12px !important;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.cancel-btn {
  @media (max-width: $sm) {
    font-size: 12px !important;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.cancel-btn-col {
  @media (max-width: $sm) {
    margin-top: 1em !important;
  }
}