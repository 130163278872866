.ant-table-cell {
  text-align: center;
}

.jobs-table {
  margin-top: 2em;
  width: 100%;
  overflow-y: hidden;

  .ant-table-wrapper {
    width: 100%;
    height: 65vh;

    @media (max-width: $md) {
      height: 65vh;
      width: 85vw;
    }

    @media (max-width: $sm) {
      display: none;
    }
  }

  .jobs-item-table {
    overflow-y: scroll;
  }

  .view-btn {
    border-color: $btn-bg;
    padding: 0 15px;
    // padding: 5px;
    background: $btn-bg;

    span {
      padding: 0;
      color: white;
    }

    &:hover {
      border: 1px solid $btn-bg;
      background-color: white;
      color: $btn-bg;

      span {
        color: $btn-bg;
      }
    }
  }

  .export-btn {
    margin-top: 2em;
  }
}

.other-table {
  margin: 2em auto auto auto;
  width: 100%;

  .ant-table-wrapper {
    width: 100%;
    min-height: 58vh;

    @media (max-width: $md) {
      height: 65vh;
      width: 85vw
    }

    @media (max-width: $sm) {
      display: none;
    }
  }

  @media (max-width: $sm) {
    margin: 0;
    // margin-left: 1em;
    margin-top: 1em;
    width: 100%;
  }

  .view-btn {
    border-color: $btn-bg;
    padding: 0px 15px;
    background: $btn-bg;

    span {
      padding: 0;
      color: white;
    }

    &:hover {
      border: 1px solid $btn-bg;
      background-color: white;
      color: $btn-bg;

      span {
        color: $btn-bg;
      }
    }
  }

  .view-btn-hover {
    border-color: $btn-bg;
    color: $btn-bg;
    background: white;

    &:hover {
      color: white;
      background: $btn-bg;

      span {
        color: white;
      }
    }
  }

  .delete-btn-hover {
    border-color: $danger-color;
    color: $danger-color;
    background: white;

    &:hover {
      color: white;
      background: $danger-color;
      border-color: $danger-color !important;

      span {
        color: white;
      }
    }
  }

  .export-btn {
    margin-top: 1em;
    padding: 0;

    @media (max-width: $sm) {
      margin-right: .5em;
    }
  }
}

.ant-table-thead th {

  .ant-table-cell {
    text-align: center;
  }
}

.ant-table-wrapper .ant-table-thead>tr>th {
  background-color: #f1f2f6;
  text-align: center;
}

.ant-table {
  display: block;
  width: 100%;

  @media screen and (max-width: 600px) {
    display: none;
  }
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 10px;

  @media (max-width: $sm) {
    display: none;
  }
}


.ant-pagination .ant-pagination-item-active {
  border-color: $nav-background;

  a {
    &:hover {
      background-color: $nav-background;
      color: white;
    }
  }
}

.ant-pagination .ant-pagination-item-active a {
  color: $nav-background;
}

.content-title {
  line-height: .5rem !important;
  font-weight: 500 !important;
  display: flex !important;
  margin: auto !important;

  @media (max-width: $sm) {
    line-height: 1.5rem !important;
  }
}

.content-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.user-content-row {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.disabled-input {
  cursor: default !important;
  color: transparent !important;
  text-shadow: 0 0 0 #5d5d5d;
}

.table-card {
  display: none;
}

@media (max-width: $sm) {
  .table-card {
    display: block;
    padding: 12px !important;
    background-color: #fafafa !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;

    .ant-card-body {
      padding: 0 !important;
    }

    .table-card-row {
      margin-bottom: .8rem;

      .submit-btn-hover {
        width: 35vw;

        .anticon {
          display: inline;
        }
      }

      .select-rate {
        width: 35vw;
      }

      .submit-btn {
        width: 35vw;
      }
    }
  }
}

.table-card-list {
  display: none;
  // height: 65vh;
  overflow-y: scroll;

  .table-card {
    margin-bottom: .7rem;
  }
}

@media (max-width: $sm) {
  .table-card-list {
    display: block;
    width: 90vw;
    overflow-y: scroll;
    // height: 63vh !important;
  }
}

.view-btn {
  border-color: $btn-bg;
  padding: 0 15px;
  font-weight: 500;
  background: $btn-bg;

  span {
    padding: 0;
    color: white;
  }

  &:hover {
    border: 1px solid $btn-bg;
    background-color: white;
    color: $btn-bg;

    span {
      color: $btn-bg;
    }
  }
}

.job-task-table {
  .ant-table-wrapper {
    min-height: max-content !important;
    margin-bottom: 1em;
  }
}