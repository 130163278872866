@import '_variable.scss';
@import './partials/common.scss';
@import './partials/Login.scss';
@import "./partials/dashboard.scss";
@import "./partials/table.scss";
@import "./partials/siteAudit.scss";
@import "./partials/jobDetails.scss";
@import "./partials/form.scss";
@import "./partials/profile.scss";
@import "./partials/notification.scss";
@import "./partials/analytics.scss";

@media (prefers-color-scheme: dark) {
    body {
        background-color: #ffffff;
    }
}