body {
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.ant-btn-default:not(:disabled):hover {
  background-color: white;
  border-color: $btn-bg

  span {
    color: $btn-bg !important;
  }
}

.ant-modal-confirm-btns {
  .ant-btn-primary {
    background: $btn-bg;
    color: white;

    &:hover {
      border-color: $btn-bg;
      background: white;
      color: $btn-bg;
    }
  }
}

.ant-btn-link {
  color: $nav-background;
}

.ant-btn-link:not(:disabled):hover {
  color: $nav-background;
  font-weight: 600;
}

.ant-table-wrapper .ant-table-column-sorter-up.active,
:where(.css-dev-only-do-not-override-19vkxoj).ant-table-wrapper .ant-table-column-sorter-down.active {
  color: $nav-background;
}

.ant-picker-dropdown .ant-picker-today-btn {
  color: $nav-background;
}

.ant-btn-default.ant-btn-dangerous:not(:disabled):hover {
  background-color: #ff5252;
  border-color: #ff5252;

  span {
    color: white;
  }
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td,
:where(.css-dev-only-do-not-override-11t214b).ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #f6f4f4c9;
}

.ant-select-selection-overflow-item {
  .ant-tag {
    .ant-tag-close-icon {
      font-size: 16px;
    }
  }
}
.ant-modal .ant-modal-title {
  font-size: 24px;
}

.ant-input:hover{
  border-color: $primary-color !important;
    border-inline-end-width: 1px;
}

.ant-input:focus {
  border-color: $primary-color !important;
}

.ant-layout{
  background: #eaf0fc !important;
}

.view-btn {
  border-color: $btn-bg;
  padding: 0 15px;
  // padding: 5px;
  background: $btn-bg;

  span {
    padding: 0;
    color: white;
  }

  &:hover {
    border: 1px solid $btn-bg;
    background-color: white;
    color: $btn-bg;

    span {
      color: $btn-bg;
    }
  }
}

.view-btn-hover {
  border-color: $btn-bg;
  color: $btn-bg !important;
  background: white;

  &:hover {
    color: white;
    background: $btn-bg !important;

    span {
      color: white !important;
    }
  }
}

.spin-loader{
  font-size: 24px;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled-btn{
  background-color: #f5f5f5 !important;
  color: #919090 !important;
  font-weight: 400 !important;
  border: 1px solid #919090 !important;
}