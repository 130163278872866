$primary-color: #1d1e4d;
$secondary-color: #686868;
$header-color: #111111;
$nav-background: #1d1e4d;
$btn-bg: #5a82f9;
$light-color: #e7e7e7;
$lighter-color: #b3b3b3;
$danger-color: #e35649;
$sm: 576px;
$md: 820px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;